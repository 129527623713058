import React from "react";
import "./ai-products-content.component.scoped.scss";
import { Box } from "@material-ui/core";
import { icons } from "../../../../../statics/icons";
import { useTranslation } from "react-i18next";
import CasesContent from "../cases-content/cases-content.component";
import { PAGE_ROLES } from "../../../types/AI-roles";
import { ContentLayout } from "../../../../../layouts/content/content.layout";
import PagesHeadComponent from "../../../../../components/pages-head-component/pages-head.component";

const AIProductsContentComponent = () => {
  const [t] = useTranslation();

  return (
    <Box>
      <ContentLayout>
        <PagesHeadComponent
          title={t("PAGES_HEAD.AI_PRODUCT_TITLE")}
          subTitle={t("PAGES_HEAD.AI_PRODUCT_SUB_TITLE")}
          logo={icons.PAGES.HEAD.AI_PRODUCTS}
        />
        <CasesContent role={PAGE_ROLES.PRODUCTS} />
      </ContentLayout>
    </Box>
  );
};

export default AIProductsContentComponent;
