import React, { useEffect, useState } from "react";
import { history } from "react-router-guard";
import { ThirdPartyComponent } from "../../modules/auth/components/third-party-login/third-party-login.componsnt";
import { AuthProvider } from "../../store";

const ThirdPartyPage = (props: any) => {
  const [params, setParams] = useState<any>({});
  const hasNullValue = (obj: any) => {
    return Object.values(obj).some((value) => value === null);
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(props.location.search);
    const mightyParams = {
      clientId: queryParams.get("client_id"),
      callbackURI: queryParams.get("redirect_uri"),
      responseType: queryParams.get("response_type"),
      scope: queryParams.get("scope"),
      state: queryParams.get("state"),
    };
    console.log(mightyParams);
    setParams(mightyParams);
    if (hasNullValue(mightyParams)) {
      history.push("/");
    }
    // eslint-disable-next-line
  }, []);
  return (
    <AuthProvider>
      <ThirdPartyComponent params={params} />;
    </AuthProvider>
  );
};

export default ThirdPartyPage;
