import { TableColumn } from "../../types/table-column";

export const AIProductsColumns: TableColumn[] = [
  // {
  //   type: "checkbox",
  //   fieldName: "checkbox",
  //   value: "id",
  //   width: 2,
  // },
  {
    type: "string",
    fieldName: "TITLE",
    value: "title",
    secondaryValue: "internalId",
    width: 20,
  },
  {
    type: "release",
    fieldName: "RELEASE",
    value: "release",
    width: 5,
  },
  {
    type: "arrayIcons",
    fieldName: "APPLICATION_AREAS",
    value: "areas",
    width: 13,
  },
  {
    type: "object",
    fieldName: "ALGORITHM",
    value: "algorithm",
    width: 13,
  },
  {
    type: "object",
    fieldName: "RISK",
    value: "category",
    width: 13,
  },
  {
    type: "questions_status",
    fieldName: "PROGRESS",
    value: "answeredQuestions",
    width: 6,
  },
  {
    type: "task",
    fieldName: "TASKS",
    value: "completedTasks",
    width: 5,
  },
  {
    type: "string",
    fieldName: "CONTACT",
    value: "keyContact",
    width: 12,
  },
  {
    type: "date",
    fieldName: "LAST_MODIFIED",
    value: "updatedAt",
    width: "fit-content",
  },
];
