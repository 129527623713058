import { Box } from "@material-ui/core";
import React from "react";

export const ContentLayout = (props: any) => {
  return (
    <Box maxWidth="1420px" m="auto" height="100%" className="px-3">
      {props.children}
    </Box>
  );
};
