import { TableColumn } from "../../types/table-column";

export const usersColumns: TableColumn[] = [
  // {
  //   type: "checkbox",
  //   fieldName: "checkbox",
  //   value: "id",
  //   width: 2,
  // },
  {
    type: "client",
    fieldName: "PERSON",
    value: "name",
    secondaryValue: "email",
    width: 20,
    hasSorting: true,
  },
  {
    type: "string",
    fieldName: "COMPANY",
    value: "company",
    width: 15,
    hasSorting: true,
  },
  {
    type: "role",
    fieldName: "ROLE",
    value: "role",
    secondaryValue: "group",
    width: 10,
    hasSorting: true,
  },
  {
    type: "string",
    fieldName: "SUBSCRIPTION",
    value: "plan",
    width: 10,
    hasSorting: true,
  },
  {
    type: "date",
    fieldName: "INVITED_ON",
    value: "created_at",
    width: 10,
    hasSorting: true,
  },
  {
    type: "date",
    fieldName: "APPROVED_ON",
    value: "accept_invitation_at",
    width: 10,
    hasSorting: true,
  },
  {
    type: "date",
    fieldName: "EXPIRATION_DATE",
    value: "subscription_expiration",
    width: 10,
    hasSorting: true,
  },
  {
    type: "status",
    fieldName: "STATUS",
    value: "status",
    width: 10,
    hasSorting: true,
  },
  {
    type: "actions",
    fieldName: "",
    value: "users",
    width: 1,
  },
];
