import React from "react";
import "./ai-inventory-content.component.scoped.scss";
import { Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { icons } from "../../../../../statics/icons";
import CasesContent from "../cases-content/cases-content.component";
import { PAGE_ROLES } from "../../../types/AI-roles";
import { ContentLayout } from "../../../../../layouts/content/content.layout";
import PagesHeadComponent from "../../../../../components/pages-head-component/pages-head.component";

const AIInventoryContentComponent = () => {
  const [t] = useTranslation();

  return (
    <Box>
      <ContentLayout>
        <PagesHeadComponent
          title={t("PAGES_HEAD.AI_INVENTORY_TITLE")}
          subTitle={t("PAGES_HEAD.AI_INVENTORY_SUB_TITLE")}
          logo={icons.PAGES.HEAD.AI_INVENTORY}
        />
        <CasesContent role={PAGE_ROLES.INVENTORY} />
      </ContentLayout>
    </Box>
  );
};

export default AIInventoryContentComponent;
