import "./table-header.component.scoped.scss";
import React, { useEffect, useState } from "react";
import { TableHeaderProps } from "../../../types/table-header-props";
import { useTranslation } from "react-i18next";
import { SorterComponent } from "../sorter/sorter.component";
import { Box, Checkbox } from "@material-ui/core";

export const TableHeaderComponent = (props: TableHeaderProps) => {
  const [t] = useTranslation();
  const { columns, selectCases, loading } = props;
  const { sort } = props.tools;
  const { sortDirection, sortColumn } = props.options;
  const { withSort } = props.options;

  const [casesChecked, setCasesChecked] = useState(false);

  useEffect(() => {
    setCasesChecked(false);
  }, [loading]);

  return (
    <tr className="table-header">
      {columns.map((column, index) => {
        return (
          <th
            style={{
              width: `${
                Number.isInteger(column.width)
                  ? column.width + "%"
                  : "fit-content"
              }`,
            }}
            key={index}
          >
            <Box className="d-flex align-items-center" style={{ gap: ".5rem" }}>
              <Box className="head">
                {column.type !== "checkbox" ? (
                  t(column.fieldName)
                ) : (
                  <Box className="checkbox-global">
                    <Checkbox
                      checked={casesChecked}
                      onChange={() => {
                        selectCases(!casesChecked);
                        setCasesChecked(!casesChecked);
                      }}
                      name="cases"
                      size="small"
                    />
                  </Box>
                )}
              </Box>
              {withSort && column.hasSorting && (
                <SorterComponent
                  isSorting={column.value === sortColumn}
                  direction={sortDirection}
                  sortColumn={sortColumn}
                  fieldName={column.value}
                  sort={sort}
                />
              )}
            </Box>
          </th>
        );
      })}
    </tr>
  );
};
